import {useState} from 'react';
import '../styles/Home.css';

const demo = () => {

  return(

    

<div className="container">


<div>
<h2 className="center-align"><b ></b></h2>
<object data="/pdf/2023AnnualReport.pdf" type="application/pdf" width="100%" height="550px"/>


</div>
</div>



  );
}
export default demo;

import { useState } from 'react';
import '../styles/Home.css';

const Committee = () => {

  return (

    <div className="container">

      <div>
        <h1 className="fbt-title"><img src="/images/homePage/Logo.jpg" alt="" width="40" height="40" className="rounded" />  for a <span className="fbt-title-sub">better</span> tomorrow </h1>
      </div>

      <div className="row">

        <div className="col-sm">

        </div>

      </div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h3 className="center-align"><b >Advisors and Committees</b></h3>
      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Advisory Council</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Adrian Barr </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Dr. Bhanu Vakkalanka</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Brian Peterson</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Chemberly Harris</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Christine Holmes</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Dr. Dakesa Pina</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > David Taylor</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Dayna Brown</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Dev Mandhyan</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Gina Lavazza</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Harry Campbell</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Julie Emig</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Karen Schmidt </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Katie Pratt</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Mark Clinch</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Meta Mickens Baker</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Pretty Reji</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Sunil Oberoi</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Vani Prasad</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Kite Fest Steering Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Chandra Chaganti</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Hari.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Hariharan Senthilnathan</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Karthik Goteti </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Nirmal Jain</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Punitha McCormick</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Sambasivarao Gurijala (Co-Chair)</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Susmita Lahiri</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Mandava.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Venkateswara Rao Mandava (Co-Chair)</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

      <p ></p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Finance Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Phani.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Phani Aytam</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Raja.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Raja Ayyapusetty</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Mandava.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Venkateswara Rao Mandava</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Project Recruitment Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/ClaireCampbell.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Claire Campbell (Chair)</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Christine Holmes </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/VasudhaPinnamaraju.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Vasudha Gadhiraju</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Giving Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Phani.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Phani Aytam</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Raja.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Raja Ayyapusetty</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Mandava.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Venkateswara Mandava</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Marketing and Technology Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Hari.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Hariharan Senthilnathan</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Rahul.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Rahul Varun Balivada</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Youth Global Citizen (YGC) Citizen Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/ClaireCampbell.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Claire Campbell </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/ShlokaRavinuthala.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Shloka Ravinuthala</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Shriyans Gurijala </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/SiddhiHindurao.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Siddhi Hindurao (Chair)</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Vedha Giriprakash</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Why I See You (YICU) Steering Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Adithya Komandur </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Carolyn Hansen</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Dr. Carla Campbell Jackson</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Hema Kasturi</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Kathy.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Kathy Weinzierl (Chair)</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Rakesh Chandra</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/ShlokaRavinuthala.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Shloka Ravinuthala</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Steam Olympics Steering Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Abarnaa Balakrishnan</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Akhila Vedam</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Dr. Dakesa Pina</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Ivan Lavazza</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Josiah Moses</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Payal Sheth</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Prasanna Nethi</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Radhika Appapusetty</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Raja.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Raja Ayyapusetty</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Sam-G.jpg" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Sam Gurijala (Chair)</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Sarita Desale</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Shloka Ravinuthala</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Sirisha Rani Kamineni</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Susmita Lahiri</span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

        <div className="col-sm">

          <table className="table table-borderless noBorder">

            <tbody>

              <tr className="row">
                <td ><h4 >Marketing Design Committee</h4></td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Anya McCormick </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Laasya Ayyapusetty</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Ravi Gadhiraju </span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Srinidhi Gurijala</span>
                  </h5>

                </td>

              </tr>

              <tr className="row">

                <td>

                  <h5>
                    <img src="/images/board-committees/Contact.png" alt="" width="25" height="25" className="rounded-circle"></img>
                    <span > Vishu Devari </span>
                  </h5>

                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
    </div>




  );
}
export default Committee;

import { useState } from 'react';
import '../../styles/Home.css';
import Wip from '../.././wip';
import YICUnav from '../.././YICU-Nav';

const yicu2023 = () => {

  return (



    <div className="container">
      <YICUnav ></YICUnav>
      <div>
        <p className="center-align"><img src="/images/YICU/2023/YICU2023.png" alt="YICU 2023" width="200" height="100" className="rounded"></img></p>
      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless">

            <tbody>

              <tr className="row">
                <td className="col-md-12 center-align"><img src="/images/YICU/2023/YICU2023winners1.jpg" alt="2023 YICU Winners" className="rounded" width="80%"></img></td>
              </tr>

              <tr className="row">

                <td className="col-md">
                  <h4 className="col-md-12 center-align"><span className="font-weight-bold"><b>YICU Winners</b>, 10 Recipients of Individual 2023 YICU Service Awards</span></h4>
                  <h4 className="col-md-12 center-align">Tanner Dowd, Pranathi Ganti, Zoe Przybylski, Adithya Komandur, Bradley Ross Jackson, Hemanth Itte, Brian Senn, Vivaan Reddy, Harshal Joshi and Pritika Jakka</h4>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-12 center-align"><img src="/images/YICU/2023/YICU2023winners2.jpg" alt="2023 YICU Winners" className="rounded" width="80%"></img></td>
              </tr>

              <tr className="row">

                <td className="col-md">
                  <h4 className="col-md-12 center-align"><span className="font-weight-bold"><b>YICU Winners</b></span></h4>
                  <h4 className="col-md-12 center-align">Team Award: NCHS ADP 2024 STEM (Tanner Dowd, Steven Robinson, Isha Gollapudi, Kylee Anderson, Jake Claus, Jason Bliss, Devin Diaz, Maya Gopinathan and Ram Parapatmuni)</h4>
                  <h4 className="col-md-12 center-align">Advocate Award : Not In Our School (NIOS) NCHS, Bloomington-Normal, IL</h4>
                  <h4 className="col-md-12 center-align">Mentor Award: Meta Mickens Baker</h4>
                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

    </div>


  );
}
export default yicu2023;

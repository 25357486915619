import {useState} from 'react';
import '../../styles/Home.css';
import Wip from '../.././wip';
import YICUnav from '../.././YICU-Nav';

const yicu2020 = () => {

  return(



    <div className="container">
    <YICUnav ></YICUnav>
    <div>
    <h3 className="logo"><img src="/images/base/Logo.jpg" alt="" width="40" height="40" className="rounded"/>  for a <span className="cRed">better</span> tomorrow </h3>
    <h3 className="center-align">YICU Service Awards 2020</h3>
    <h3 className="center-align">Winners</h3>
    <p >&nbsp;</p>
    </div>

    <div><hr class="hr-line"></hr></div>

    <p >&nbsp;</p>
    <div className="row">

    <div className="col-sm">

    <h4 className="center-align"><b >Individual Awards</b></h4>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <h6 className="center-align cGray">Shashi Salavath</h6>
    <h6 className="center-align cGray">Isha Gollapudi</h6>
    <h6 className="center-align cGray">Satvika Boyina</h6>
    </div>

    <div className="col-sm">

    <h6 className="center-align cGray">Erica Rosenberger</h6>
    <h6 className="center-align cGray">Rachel Dawson</h6>
    <h6 className="center-align cGray">Jasmyn Jordan</h6>
    </div>

    <div className="col-sm">

    <h6 className="center-align cGray">Yvin Shin</h6>
    <h6 className="center-align cGray">Gavin Cunningham</h6>
    <h className="center-align cGray">Heather Dawson</h>
    </div>

    </div>

    <div><hr className="line2"></hr></div>

    <div className="row">

    <div className="col-sm">

    <h4 className="center-align"><b >Team Award</b></h4>
    <h6 className="center-align">The Normal West Black Student Union</h6>
    </div>

    </div>

    <div><hr className="line2"></hr></div>

    <div className="row">

    <div className="col-sm">

    <h4 className="center-align"><b >Advocate Award</b></h4>
    <h6 className="center-align">Helping Hands Community Center (HHCC)</h6>
    </div>

    </div>

    <div><hr className="line2"></hr></div>

    <div className="row">

    <div className="col-sm">

    <h4 className="center-align"><b >Mentor Award</b></h4>
    <h6 className="center-align">Shri Narayan Chandak</h6>
    </div>

    </div>

    <div><hr className="line2"></hr></div>

    <div className="row">
    <h4 className="center-align"><b >FBT is excited to make a $250 donation towards the non-profits chosen by the award winners.</b></h4>
    </div>

    <div className="row">

    <div className="col-sm">

    <h4 className="center-align"><a href="https://www.facebook.com/4ABetterTomorrow/videos/376058036781953/"><b>YICU 2020 Event Video</b></a></h4>
    <p className="center-align"><a href="https://www.facebook.com/4ABetterTomorrow/videos/376058036781953/"><img src="Images/2020/YICU2020-Play.png" alt="" width="50%" className="rounded" /></a></p>
    <p >&nbsp;&nbsp;</p>
    </div>

    </div>

    <div><hr class="hr-line"></hr></div>

    <h4 className="center-align ">YICU 2021 Youth Nominees</h4>
    <table className="table table-borderless">
    <tbody ></tbody>
    <tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Akhila-Arla.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Akhila Arla </span> is a second-generation immigrant who grew up in America. She loves traveling and being involved in the community. Akhila likes voicing her opinions and being true to herself.  Although am freshman at Normal Community High School, she loves the sense of responsibility she has, including the sense of doing something that matters. She acknowledges she has such a long way to go to ensure her life turns out the way she wants it to and believes YICU is a great start.</h6><h6 className="text-primary">Non-Profit Akhila  Supports: <span className="font-weight-bold">Community Health Care Clinic</span></h6></td></tr>
    <tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Mihir-Bendre.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Mihir Bendre </span> is a senior at Normal Community School. He is passionate about physics, math, and chemistry. He likes playing soccer and frisbee, reading books and watching movies and enjoys volunteering. In the future, Mihir wants to work in sustainable waste disposal or renewable energy in the future, because he recognizes them as big problems.</h6><h6 className="text-primary">Non-Profit Mihir  Supports: <span className="font-weight-bold">Promise Council</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Nikhit-Bhamidipati.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Nikhit Bhamidipati, </span> is an eighth grader at George L Evans Jr. High. In addition to volunteering for many organizations, he takes an interest in basketball and track. He enjoys cooking and making snacks for himself. His favorite subjects are math and reading and he likes to travel to India to visit his relatives and also enjoys traveling to other places.</h6><h6 className="text-primary">Non-Profit Nikhit  Supports: <span className="font-weight-bold">Unity Community Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Satvika-Boyina.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Satvika Boyina </span> is a sophomore at University High School. As a young child, her parents taught her to be grateful for the life she lives. A few years ago, after going outside the country she saw how many people live in poverty and was devastated. From that point on, Satvika wanted to make sure that whatever she does should have an impact on making the world a better place and is trying to serve her community to the best of her ability.</h6><h6 className="text-primary">Non-Profit Satvika  Supports: <span className="font-weight-bold">Community Cancer Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Dhyan-Chaudhari3.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Dhyan Chaudhari </span> is an eighth grader at Evans Junior High School. His favorite subjects are math and science and he loves reading books, playing the saxophone, and solving Rubi’s Cubes. His best time is 19.75 seconds. Dhyan has been part of Ekal Helping Hands Group and has volunteered in organizing several events like serving breakfast at the Salvation Army, setting up water bottle stations in Miller Park, Secret Santa, helping out the local Hindu temple during various festivals.</h6><h6 className="text-primary">Non-Profit Dhyan  Supports: <span className="font-weight-bold">Project Oz</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Chloe-Cope.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Chloe Cope </span> is a Junior at Bloomington High School. She is active in her school's band, theatre, and soccer program, as well as her church youth group. Chloe has participated in student government for 3 years now and has been a drama club officer for 2 years. She hopes to go to college and join the FBI as a behavioral profiler in the future.</h6><h6 className="text-primary">Non-Profit Chloe  Supports: <span className="font-weight-bold">Wishbone Canine Rescue</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Gavin-Cunningham.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Gavin Cunningham's </span> compassion for others and passion for social justice propels his voice to promote positive change in our community. His four years on the NCWHS speech team provided skills as he co-founded the Bloomington Normal Youth Activists and held leadership roles in Not in Our School. His commitment to service has also led him to the pursuit of a degree in Special Education at Illinois State. He aspires to become an elected official following his teaching tenure.</h6><h6 className="text-primary">Non-Profit Gavin  Supports: <span className="font-weight-bold">Not In Our Town/School</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Heather-Dawson.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Heather Dawson </span> is a freshman at Heartland Community College where she is studying Agriculture Business. She has been an active member of the Olympia Pacesetters 4-H Club in Stanford. She spends many hours of community service working at the club's Helping Hands food pantry and community garden that provides fresh produce to the food pantry. Heather enjoys caring for her goats and chickens and taking pictures.</h6><h6 className="text-primary">Non-Profit Heather  Supports: <span className="font-weight-bold">Helping Hands Community Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Rachel-Dawson.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Rachel Dawson </span> is a junior in high school. She is president of the Olympia Pacesetters 4-H club and a founding member of McLean County Hunger Ambassadors. She is active in her church and is a member of the Heyworth Christian Youth Group. Rachel loves playing travel softball. She takes care of her goats and other animals and works with her sisters in the home garden at the family farm. Community service is an important part of her life.</h6><h6 className="text-primary">Non-Profit Rachel  Supports: <span className="font-weight-bold">Helping Hands Community Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/LeeAnn-Garlish.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">LeeAnn Garlish </span> is originally from Pekin, Illinois. She came to Bloomington from Chicago, based solely on the fact that she wanted her life to be more about helping others than it had been. Lee Ann has always studied and loved the art of theatre and hopes to show others this avenue of Creativity. She is a student at ISU and works as a staff member for Seedling Theatre, a branch of Illinois Voices Theatre.</h6><h6 className="text-primary">Non-Profit LeeAnn  Supports: <span className="font-weight-bold">Voices Theatre – Seedling Theatre</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Isha-Gollapudi.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Isha Gollapudi, </span> is a freshman at NCHS. She is very involved in the local art community. Public art especially interests her because it is not just beautification, but it can bring awareness on many social, cultural, and local topics. During the current pandemic, she partnered with various organizations to paint for a purpose -  to thank, motivate and support the healthcare professionals and essential workers in our community. Isha drives the innovative team for local charity Little Free Pantry, where she is responsible for bringing new projects and concepts to life with creative ideas.</h6><h6 className="text-primary">Non-Profit Isha  Supports: <span className="font-weight-bold">Community Cancer Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Amaya-Hursey.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Amaya Hursey. </span> Is a sophomore at Normal Community West High School. She is a member of the Black Student Union, Not In Our School, the Jule Foundation, Jack and Jill of America, and Mt. Pisgah Baptist Church. She plans to attend an Historical Black University such as Spelman or Howard University. She wants to be either a psychologist or a lawyer. She plans to become a lawyer because the system is made to be against anything a Black man/woman says. She wants “to help change and give African Americans the chance at this thing called life without being sent to death.” </h6><h6 className="text-primary">Non-Profit Amaya  Supports: <span className="font-weight-bold">The Jule Foundation</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Marissa-Jones.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Marissa Jones </span> is a graduate of Illinois State University where she received her Bachelor of Science Degree in Public Relations. Currently, she is in the graduate program at ISU where she is in progress to receive her Master's of Science in Communication. Marissa desires to work in Agency Public Relations with a focus in Non-Profit. She has worked in the Warehouse, Mission Mart, and in the Kitchen to prepare meals at Home Sweet Home Ministries.</h6><h6 className="text-primary">Non-Profit Marissa  Supports: <span className="font-weight-bold">Project Oz</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Jasmyn-Jordan3.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Jasmyn Jordan </span> is a senior at Normal Community West High School. She is the founder and president of the Normal West Black Student Union, secretary of National English Honors Society, Vice President of Communications on Student Council, a freshmen mentor in the FMP program, and runs for Lady Wildcat Track and Field. She is an active member of the National Honors Society and Promise Council. Jasmyn is a Master Junior Zookeeper at Miller Park Zoo, and member of the Youth Action Board of the Illinois Prairie Community Foundation.</h6><h6 className="text-primary">Non-Profit Jasmyn  Supports: <span className="font-weight-bold">Youth Engaged in Philanthropy</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Madyson-McSwain.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Madyson McSwain </span> possesses many strengths including being a passionate and enthusiastic student who has proven to be reliable, trustworthy and honest. She also shares she is kind, smart, and loves to help others. Madyson is a valued leader, holding the position of Treble Choir className President and one of the Captains of the varsity Lady Raiders. Madyson volunteers at her church and her grandmother’s nursing home.</h6><h6 className="text-primary">Non-Profit Madyson  Supports: <span className="font-weight-bold">Boys and Girls Club</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Grace-Melick.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Grace Melick, </span> is a Summa Cum Laude graduate of Olympia High School and is a freshman at Rhodes College. At Olympia she participated in chamber choir, plays, musicals, student council, NHS, and speech. She was the 2020 Illinois High School Association State Runner-Up in Oratorical Declamation. Her interests include public speaking, reading, and learning. She is studying sociology and wants to be a trial lawyer.</h6><h6 className="text-primary">Non-Profit Grace  Supports: <span className="font-weight-bold">Danvers-Carlock Food Pantry</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Dhriti-Mudigonda.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Dhriti Mudigonda. </span> is a junior at Normal Community High School. He is involved in several organizations including Future Business Leaders of America (FBLA) and the tennis team. She is also a part of the Little Free Pantry located in Normal. Dhriti is also very involved in the Hindu community and her culture, and which she attributes to making her who she is today.</h6><h6 className="text-primary">Non-Profit Dhriti  Supports: <span className="font-weight-bold">Community Cancer Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Daja-Parker.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Daja Parker, </span> who is from Chicago, and was very involved in the Baptist church. As she grew older, she became more independent and worked at the Chicago Park District. She attended Chicago Bulls College Prep where she learned to think more critically and serve her community. She is a fourth-year student at ISU working towards a bachelor’s degree in political communication and minor in political science. Daja wants to be a lawyer and be the difference she would like to see in the world. Daja volunteers with the Revolutionary Unite group and civic engagement department at ISU.</h6><h6 className="text-primary">Non-Profit Daja  Supports: <span className="font-weight-bold">Bloomington-Normal YMCA</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Saraschandrika-Puvvala.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Saraschandrika Puvvala </span> is a ninth grader at University High School. Her favorite thing to do is to spend time with family and friends. In her free time, she likes the outdoors, singing, playing tennis, and badminton. She has been a part of Ekal Helping Hands, Balagokulam, SEWA and Yuva Club for several years. These organizations have helped her get more experience with being a leader. Saraschandrika loves serving people around her and has been a part of many community service activities.</h6><h6 className="text-primary">Non-Profit Saraschandrika  Supports: <span className="font-weight-bold">Ekal Vidyalaya</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Erica-Rosenberger.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Erica Rosenberger </span> is a Junior at University High School. She uses youth voices to push for a brighter and more equitable future for the coming generations. Prior to being involved in many activities at her school, Erica felt voiceless as she recognized the need for a change in her school which resulted in her rebooting the Not In Our School at U-High. Heavily involved in NIOS’s Steering Committee along with other not-for-profit organizations, Erica is given the space to speak out and encourage others to speak out about the intolerances they see.</h6><h6 className="text-primary">Non-Profit Erica  Supports: <span className="font-weight-bold">Not In Our Town/School</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Rickiya-Ross.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Rickiya Ross </span> is seventeen years old and attends Normal Community West High School. She is part of her school’s Black Student Union and has taken on the role as the Historian, which sheds light on Black history and culture. She often gives back to her community by serving the homeless, comforting the sick, and educating her community. Rickiya lives by her favorite quote “Do unto others as you would have done unto you” (Matthew 7:12) which she will take into her future to keep her grounded in her morals and values.</h6><h6 className="text-primary">Non-Profit Rickiya  Supports: <span className="font-weight-bold">Special Olympics</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Sharanya-Rotte.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Sharanya Rotte </span> is a Sixteen year old who attends Normal Community High School. When she started doing community service, she did not know the real meaning behind it but after a couple years of volunteering, she came to understand the real meaning. She enjoys spending weekends serving whether it is with the elderly, giving out food to the hungry, or picking up trash. Sharayana serves as a Co-Lead of the local Little Food Pantry, coordinates events for SEWA International and participates in FBLA.</h6><h6 className="text-primary">Non-Profit Sharanya  Supports: <span className="font-weight-bold">Unity Community Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Shashi-Salavath.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Shashi Salavath </span> is a local youth volunteer and an eighth grader at Evans Jr. High School.  His hobbies include playing chess, soccer and is a member of a 4-H robotics club. He is very passionate about giving back to the community that has already given so much to him. Shashi looks forward to continuing to volunteer for the next several years. He has served on numerous service projects including Little Food Pantry, SEWA International and Bloomington-Normal Area Scholastic Chess.</h6><h6 className="text-primary">Non-Profit Shashi  Supports: <span className="font-weight-bold">West Bloomington Revitalization Project</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Lineeth-Sareddy.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Lineeth Sareddy </span> is a student at Normal Community High School. As a young child, he was shy and soft-spoken. One could commonly find him hiding in the back, and would always be the one scared to raise his hand. As he grew through the years, he learned more about the world around him. By slowly building his confidence and networking with other people, he has grown to set new sights for himself. He now values and cares for many things in life, rather than being a shy and unconfident kid only worried about the little things. At School, Lineeth serves on the student council and volunteers with Key Club and St. Jude's Club and participates in FBLA.</h6><h6 className="text-primary">Non-Profit Lineeth  Supports: <span className="font-weight-bold">Community Cancer Center</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Aditi-Sharma.jpg"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Aditi Sharma </span> is Seventeen and attends Normal Community High School where she is co-leader of Not In Our School and is on the NIOS Steering Committee. She also founded the Inclusive Education Coalition. She believes her efforts help make the schools safe and education system fair and more inclusive.  She enjoys music, reading and watching Netflix.</h6><h6 className="text-primary">Non-Profit Aditi  Supports: <span className="font-weight-bold">Not In Our Town/School</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Yvin-Shin.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Yvin Shin </span> is a Korean-American high senior who attends University High School. She serves on the Not In Our School and Bloomington-Normal Welcoming leadership teams within her town of Normal, Illinois, and is the president of U-High’s student Diversity Committee. Yvin also participates in speech and debate, Student Senate, and plays the cello and played tennis before the novel Coronavirus pandemic. She plans to major in International Relations with the potential of also majoring or minoring in Cognitive Science or Applied Mathematics, and hopes to eventually become a professor in her field of study.</h6><h6 className="text-primary">Non-Profit Yvin  Supports: <span className="font-weight-bold">Not In Our Town/School</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Eden-Susong.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Eden Susong </span> is a junior at University High School. She uses her voice and love of music to spread awareness and teach younger youth to find their voices. Through countless organizations at her school, church, and around town, she loves to volunteer her already sparse time to help those around her. Her love of helping others feel heard and safe, transcends leadership and happiness. Eden loves seeing others smile and find their own unique place in this world. Eden wants to continue to dedicate her time to helping the homeless population in this community find their place and start to sustain themselves.</h6><h6 className="text-primary">Non-Profit Eden  Supports: <span className="font-weight-bold">Project Oz</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Sonika-Tamilarasan.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Sonika Tamilarasan </span> is a sophomore at Normal Community High School. She has volunteered with SEWA International, Ekal Vidyala, Salvation Army and through other clubs at school. She is an enthusiastic person and looks forward to making a positive impact in the community. Sonika volunteers with Ekal and at school, she serves on the student council and participates in FBLA.</h6><h6 className="text-primary">Non-Profit Sonika  Supports: <span className="font-weight-bold">The Immigration Project</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Aniya-Thompson.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Aniya Thompson </span> is a senior at Normal Community High School who self describes as “a natural-born leader” who has a passion for helping others. She is President of the Choir, Theatre, and Student Union at NCHS. She is an activist against injustice whenever they arrive, and is extremely determined. She developed  a love of music at a young age, and wants to use that passion to help inspire and teach others just like her. Aniya volunteers with CIDSO Buddy Walk, Gift Drive with Zeta Phi Beta, Turkey Delivery with Home Sweet Home Ministries and Freshman Mentorship Program at NCHS.</h6><h6 className="text-primary">Non-Profit Aniya  Supports: <span className="font-weight-bold">Not In Our Town/School</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Justin-Turner3.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Justin Turner </span> is Sixteen & a student at Normal Community West High School. When he first began providing community service, he did not know the real meaning behind it, but now understands what service is and has found a true passion for volunteering and serving his community. He spends his weekend serving the community, by spending time with the elderly, helping give food to the hungry or picking up trash. He loves the community because of the great opportunities and support it has given him. Justin says that serving the community is something he can’t imagine not doing.</h6><h6 className="text-primary">Non-Profit Justin  Supports: <span className="font-weight-bold">The Jule Foundation</span></h6></td></tr>
<tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Addison-Weaver.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Addison “Addi”  </span> Weaver is a junior at Bloomington High School who believes that every moment is a possibility for positive change. A leader in BHS Promise Council (BHSPC) and Not In Our School, Addi encourages students to use their voices and uplift their classNamemates. She joined the BHSPC board her freshman year. As the Chair of BHSPC, she works to create safe spaces within her school community for all students. These opportunities have given her the confidence to speak up and grow into a leader within her school community. Addi is also the Historian of the BHS Drama Club.</h6><h6 className="text-primary">Non-Profit Addison  Supports: <span className="font-weight-bold">Not In Our Town/School</span></h6></td></tr>

    </table>

    <div><hr class="hr-line"></hr></div>

    <h4 className="center-align ">YICU 2021 Team Nominees</h4>
    <table className="table table-borderless">
    <tbody ></tbody>

    <tr  className="row"><td className="col-md-12 center-align" ><img src="/images/YICU/2020/Ekal-Helping-Hands.png"alt=" width='60%'"  className="rounded" /></td></tr>
<tr><td className="col-md-12 center-align"><h6><span className="font-weight-bold">Ekal Helping Hands</span> is a leadership and service team in McLean County. While it started as some friends trying to make a difference, it now has 50+ students, ranging from the ages of 7-18! The team has organized and participated in numerous service activities including: Valentine’s day at senior citizen centers, cooking and serving food at the Salvation Army Shelter, and creating care packages for US soldiers serving overseas. During the pandemic they initiated zoom calls with community leaders as guest speakers, ensuring youth stayed connected during uncertain times. The team hopes to continue making a difference in the future!</h6><h6 className="text-primary">Non-Profit Ekal Helping Hands Supports: <span className="font-weight-bold">Ekal Vidyalaya</span></h6><div>
<span className="col-md-2 align-center" >Akshayasri Muppuru</span><span className="col-md-2 align-center" >Amulya Acharya</span><span className="col-md-2 align-center" >Anchita Sayani</span><span className="col-md-2 align-center" >Anisha Amaravadi</span><span className="col-md-2 align-center" >Avani Rai</span><span className="col-md-2 align-center" >Dhyan Chaudhari</span>
<span className="col-md-2 align-center" >Divya Bhookya</span><span className="col-md-2 align-center" >Gomati Somasundaram</span><span className="col-md-2 align-center" >Isha Gollapudi</span><span className="col-md-2 align-center" >Janani Dharmarajan</span><span className="col-md-2 align-center" >Joshitha Bodavula</span><span className="col-md-2 align-center" >Karthika Nair</span>
<span className="col-md-2 align-center" >Keshav Sagi</span><span className="col-md-2 align-center" >Krishiv Sreejith</span><span className="col-md-2 align-center" >Maya Gopinathan</span><span className="col-md-2 align-center" >Mihir Bendre</span><span className="col-md-2 align-center" >Nikhit Bhamidipati</span><span className="col-md-2 align-center" >Ojas Shah</span>
<span className="col-md-2 align-center" >Pranathi Ganti</span><span className="col-md-2 align-center" >Pranav Bhookya</span><span className="col-md-2 align-center" >Pranavi Chaganti</span><span className="col-md-2 align-center" >Pritha Chatterjee</span><span className="col-md-2 align-center" >Saraschandrika Puvvala</span><span className="col-md-2 align-center" >Satvika Boyina</span>
<span className="col-md-2 align-center" >Shachi Sharad</span><span className="col-md-2 align-center" >Sharanya Srivastava</span><span className="col-md-2 align-center" >Shashi Salavath</span><span className="col-md-2 align-center" >Shreenija Daggavolu</span><span className="col-md-2 align-center" >Shreni Jain</span><span className="col-md-2 align-center" >Siri Harish</span>
<span className="col-md-2 align-center" >Snigdha Talasu</span><span className="col-md-2 align-center" >Sonika Tamilarasan</span><span className="col-md-2 align-center" >Sri Jaladi</span><span className="col-md-2 align-center" >Suhana Kashinathappa</span><span className="col-md-2 align-center" >Vineetha Gurrala</span>
</div></td></tr>

<tr  className="row"><td className="col-md-12 center-align" ><img src="/images/YICU/2020/The-Normal-West-Black-Student-Union.png" alt="" width="60%"  className="rounded" /></td></tr>

<tr><td className="col-md-12 center-align"><h6><span className="font-weight-bold">The Normal West Black Student Union</span> welcomes students of all races. This student-led organization promotes positivity and acceptance through informing others of black culture; strives for academic excellence; develops leaders; increases student participation inside and outside of the school community; and aids humanity through volunteerism and community service. The Normal West Black Student Union has been an organization since May 2019. This organization has hosted donation drives, led Black History Month festivities, organized rallies and marches, and much more!</h6><h6 className="text-primary">Non-Profit The Normal West Black Student Union Supports: <span className="font-weight-bold">Child Protection Network</span></h6><div>
<span className="col-md-2">Airies Woods</span><span className="col-md-2" >Alisha Wallace-Lockett</span><span className="col-md-2" >Amaya Hursey</span><span className="col-md-2">Amon Brock</span><span className="col-md-2" >Andrea Brown</span><span className="col-md-2" >Andrew Levitt</span>
<span className="col-md-2" >Ariana Bertrand</span><span className="col-md-2">Aryelle Thoennes</span><span className="col-md-2" >Ashlyn Givens</span><span className="col-md-2">Ashyanna Watson</span><span className="col-md-2" >Audra Fish</span><span className="col-md-2" >Austin Feaman</span>
<span className="col-md-2" >Ava Miller</span><span className="col-md-2">Avery Gibson</span><span className="col-md-2" >Caitlin Smith</span><span className="col-md-2" >Carmella Anderson</span><span className="col-md-2" >Carter Bergeron</span><span className="col-md-2" >Cassidy Chandler</span>
<span className="col-md-2" >Cerenity Andrews</span><span className="col-md-2">Cheyenne Rodriguez</span><span className="col-md-2">Christian Joaquin</span><span className="col-md-2">Christiana</span><span className="col-md-2">Connor Henson-Stroud</span><span className="col-md-2">Cora Vogelsand</span>
<span className="col-md-2">Devin Whitsey</span><span className="col-md-2">Eli Pittman</span><span className="col-md-2">Elizabeth Hapgood</span><span className="col-md-2">Ellie Diggins</span><span className="col-md-2">Ellie Monagle</span><span className="col-md-2">Emerrie Coppage</span>
<span className="col-md-2">Gabe Teplitz-Crawford</span><span className="col-md-2">Gianna Glass</span><span className="col-md-2">Grace Storm</span><span className="col-md-2">Hannah Gaff</span><span className="col-md-2">Iayanna Scott</span><span className="col-md-2">Isaac Demosthenes I</span>
<span className="col-md-2">yanla Allen Benson</span><span className="col-md-2">Jakarri Thorton-Carter</span><span className="col-md-2">Jamyiah Jefferson</span><span className="col-md-2">Jasmine Tennin</span><span className="col-md-2">Jasmyn Jordan</span><span className="col-md-2">Jaye' Eau' Patton</span>
<span className="col-md-2">Jemeriya Jefferson</span><span className="col-md-2">Jessica Uphoff</span><span className="col-md-2">Jonathan Thurman</span><span className="col-md-2">Jordan Thomas</span><span className="col-md-2">Julianna Sadiku</span><span className="col-md-2">Justin Turner</span>
<span className="col-md-2">Kai Marie Gibson</span><span className="col-md-2">Kaleah</span><span className="col-md-2">Kamyra Williams</span><span className="col-md-2">Karizma Rutter</span><span className="col-md-2">Kaylen Gammons</span><span className="col-md-2">Kemyiah Strong</span>
<span className="col-md-2">Ketsia Katanda</span><span className="col-md-2">Kyleigh Weir</span><span className="col-md-2">Kylie Black</span><span className="col-md-2">Lamaya Findley</span><span className="col-md-2">Laure Diamonika</span><span className="col-md-2">Lily</span>
<span className="col-md-2">Lorelai Kuchefski</span><span className="col-md-2">Maddie Beirne</span><span className="col-md-2">Maddie Beirne</span><span className="col-md-2">Makayla Vinson</span><span className="col-md-2">Mariah</span><span className="col-md-2">Mariela Arias</span>
<span className="col-md-2">Marissa Hagler</span><span className="col-md-2">Mia Postlewaite</span><span className="col-md-2">Mikhael Crawford</span><span className="col-md-2">Monay Duvendack</span><span className="col-md-2">Naya Simone</span><span className="col-md-2">Nichole Pearl</span>
<span className="col-md-2">Noelle Bush</span><span className="col-md-2">Presley Miles</span><span className="col-md-2">Rickiya Ross</span><span className="col-md-2">Rocoya Henry</span><span className="col-md-2">Sahara Williams</span><span className="col-md-2">Samantha Peterson</span>
<span className="col-md-2">Sicilia Rena</span><span className="col-md-2">Sophia Whiteaker</span><span className="col-md-2">Spencer Pontius</span><span className="col-md-2">Sylvia Techmanski</span><span className="col-md-2">Symone Davis</span><span className="col-md-2">Taylor Miller</span>
<span className="col-md-2">Tabria Kirkwood-Williams</span><span className="col-md-2">Taylor Slabe</span><span className="col-md-2">Taylor Thompson</span><span className="col-md-2">Undrell</span><span className="col-md-2">Zhakir Smith</span>
</div></td></tr>





    </table>

    <div><hr class="hr-line"></hr></div>

    <h4 className="center-align ">YICU 2021 Advocate Nominees</h4>
    <table className="table table-borderless">
    <tbody ></tbody>

    <tr  className="row"><td className="col-md-12 center-align" ><img src="/images/YICU/2020/Helping-Hands-Community-Center.png"alt="" width="60%"  className="rounded" /></td></tr>
    <tr><td className="col-md-12"><h6><span className="font-weight-bold">Helping Hands Community Center</span>(HHCC) is a community service-learning project started and sustained by youth of the Olympia Pacesetters 4-H Club and other youth volunteers. The food pantry serves the residents of Stanford and Allin Township. The clothing exchange is free and open to anyone. The youth plan, direct, and execute programs and projects to enhance the center. This resource is developed to meet the needs of families in need.</h6><h6 className="text-primary">Non-Profit Helping Hands Community Center Supports: <span className="font-weight-bold">Helping Hands Community Center</span></h6><div>
    </div></td></tr>

    <tr  className="row"><td className="col-md-12 center-align"><img src="/images/YICU/2020/Youth-Engaged-in-Philanthropy-YEP.png"alt="" width="60%" className="rounded" /></td></tr>
    <tr><td className="col-md-12"><h6><span className="font-weight-bold">Youth Engaged in Philanthropy</span>(YEP), a youth-led initiative under the Illinois Prairie Community Foundation, is a group of 25-30 high school students from Dewitt, Livingston, Logan and McLean Counties with a $10,000 philanthropic budget to allocate grant funds to local youth-focused initiatives.</h6><h6 className="text-primary">Non-Profit Youth Engaged in Philanthropy Supports: <span className="font-weight-bold">Boys and Girls Club</span></h6><div>
    </div></td></tr>



    </table>

    <div><hr class="hr-line"></hr></div>

    <h4 className="center-align ">YICU 2021 Mentor Nominees</h4>
    <table className="table table-borderless">
    <tbody ></tbody>

    <tr  className="row"><td className="col-md-4 center-align" ><img src="/images/YICU/2020/Carolyn-Hansen.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Carolyn Hansen</span> holds two degrees in education from The Ohio State University. She was the Program Director at the Student Union at University of Northern Iowa and the International Administrator for Circle K International (collegiate group of Kiwanis International). After a 20 year career in insurance, mostly in learning and development, she completed her career by serving as the 4-H/Youth Development Educator for University of Illinois Extension in McLean County. Carolyn has lived in eight states and in each state she has been a volunteer in 4-H and Kiwanis. She is currently president of the Kiwanis Club of the Olympia area, which she also chartered.</h6><h6 className="text-primary">Non-Profit Carolyn Supports: <span className="font-weight-bold">Helping Hands Community Center</span></h6></td></tr>
    <tr  className="row"><td className="col-md-4 center-align"><img src="/images/YICU/2020/Shri-Narayan-Chandak.png"alt="" width="150" height="150" className="rounded" /></td><td className="col-md-8"><h6><span className="font-weight-bold">Shri Narayan Chandak</span> has been fortunate to see life in its entirety. He grew up in two rural villages in India, each without electricity, phones or any modern amenities. Soon after graduating from the Illinois Institute of Technology, Chicago, he started working and met his future wife, Asha. They started their married life in Chicago land, and moved to Bloomington. Here, both of his daughters got their college education at ISU, UIUC and Columbia in Law and Medicine. Shri Narayan volunteers with Toastmasters, Rotary Club, Interfaith activities, Youth camps, education, and rural development. He initiated the annual Diwali Food Drive for local charities, youth camps and G-LIFE, efforts he has been collaborating with Youth for the last two decades in Central Illinois. </h6><h6 className="text-primary">Non-Profit Shri Narayan Supports: <span className="font-weight-bold">Ekal Vidyalaya</span></h6></td></tr>


    </table>

    <div><hr class="hr-line"></hr></div>

    </div>



  );
}
export default yicu2020;

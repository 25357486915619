import {useState} from 'react';
import '../../styles/Home.css';
import Wip from '../.././wip';
import YGCnav from '../.././YGC-Nav';

const ygc2021 = () => {

  return(




    <div className="container">
    <YGCnav ></YGCnav>
    <div>
    <p className="center-align"><img src="/images/ygc/2021/YGC_2021.jpg"alt="YGC 2021" width="200" height="100" className="rounded center-align" ></img></p>
    </div>

    <div>
    <h3 className="center-align"><b >className of 2021</b></h3>
    </div>

    <div><hr class="hr-line"></hr></div>

    <p >&nbsp;&nbsp;</p>
    <div className="row">

    <div className="col-sm">

    <table className="table table-borderless">

    <tbody>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Yajath Narra</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Umika Kuricheti</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Soren Gjesfjeld</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Shruthi Vudaru</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Samuel Jackson</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Manjari Dheepan</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Hannah Gaff</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Diego Gonzalez</span></h6>
    </td>

    </tr>

    <tr className="row">

    <td className="col-md">
    <h6 className="center-align"><span className="font-weight-bold">Adi Menon</span></h6>
    </td>

    </tr>

    </tbody>

    </table>

    </div>

    </div>

   </div>


  );
}
export default ygc2021;

const YICUnav = () => {
  return(

    <div class="row">
      <p>
      <span><b>Flashback:</b></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2023" role="button">2023</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2022" role="button">2022</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2021" role="button">2021</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2020" role="button">2020</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2019" role="button">2019</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2017" role="button">2017</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/yicu2016" role="button">2016</a></span><span> </span>
      </p>
      <p >&nbsp;</p>
    </div>


  );
}
export default YICUnav;

import { useState } from 'react';
import '../../styles/Home.css';
import Wip from '../../wip';
import STEAMnav from '../../STEAM-Nav';

const STEAM2027 = () => {

  return (



    <div className="container">
      <STEAMnav ></STEAMnav>
      <div>
        <Wip ></Wip>
      </div>

    </div>


  );
}
export default STEAM2027;

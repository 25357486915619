import {useState} from 'react';
import '../../styles/Home.css';
import Wip from '../.././wip';
import YICUnav from '../.././YICU-Nav';

const yicu2019 = () => {

  return(



    <div className="container">
    <YICUnav ></YICUnav>
    <div>
    <h3 className="logo"><img src="/images/base/Logo.jpg" alt="" width="40" height="40" className="rounded"/>  for a <span className="cRed">better</span> tomorrow </h3>
    <h3 className="center-align">YICU Service Awards 2019</h3>
    <h3 className="center-align">Nominees</h3>
    <p >&nbsp;</p>
    </div>

    <div><hr class="hr-line"></hr></div>

    <p >&nbsp;</p>
    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Al-Wallace.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Alonnah Wallace</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Amara-Sheppard.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Amara Sheppard</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Amber-Waninger.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Amber Waninger</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Kathy-Weinzierl.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Anthony Miller</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/base/Contact-1.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Bradley Bruhl</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Eric-Veal.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Brady Veal</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Camryn-Restivo.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Camryn Restivo</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/casper-kerfoot.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Cas Kerfoot</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Gavin-Vlogs.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Gavin Cunningham</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Heather-Dawson.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Heather Dawson</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Imani-Sanchez.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Imani Sanchez</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Isha-Kiran-Gollapudi.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Isha Gollapudi</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Jacob-Henderson.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Jacob Henderson</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Jasmyn-Jordan.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Jasymn Jordan</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Jennie-Mulholland.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Jennie Mulholland</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Kari.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Jonah Prokes</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/judie-Jude-Elisabeth.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Jude Lahr</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Kaitlynn-Whitecotton.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Kaitlynn Whitecotton</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Kyleigh-McBride.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Kyleigh McBride</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/MacKenna-Gravel.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">MacKenna Gravel</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Madalyn-Schmitt.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Madalyn Schmitt</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/me12-Madi-Ching.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Madi Ching</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Rachel-Dawson.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Rachel Dawson</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/erbman.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Reace Erbe</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Savannah-H.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Savannah Harpel</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/ShreenijaReddy-Daggavolu.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Shreenija Daggavolu</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Sriya-Darsi.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Sriya Darsi</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Sunny-Shaheen.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Sunny Shaheen</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Tristan-Bixby.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Tristan Bixby</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/V-Shenoy.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Venkatesh Shenoy</h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Wah-Chook.png" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Wah Chook</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Yash-Gollapudi.jpg" alt="" width="150" height="150" className="rounded" /></p>
    <h6 className="center-align">Yash Gollapudi</h6>
    </div>

    <div className="col-sm">

    <p className="center-align"></p>
    <h6 className="center-align"></h6>
    </div>

    </div>

    <div className="row">

    <div className="col-sm">

    <p className="center-align"><img src="/images/YICU/2019/Carolyn-Hansen.jpg" alt="" width="400" height="300" className="rounded" /></p>
    <h6 className="center-align">Olympia Pacesetters 4-H Club</h6>
    </div>

    </div>

    <div><hr class="hr-line"></hr></div>

    </div>




  );
}
export default yicu2019;

const YGCnav = () => {
  return(

    <div class="row">
      <p>
      <span><b>Flashback:</b></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2024" role="button">2024</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2023" role="button">2023</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2022" role="button">2022</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2021" role="button">2021</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2019" role="button">2019</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2018" role="button">2018</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2017" role="button">2017</a></span><span> </span>
      <span><a className="btn btn-info btn-sm" href="/ygc2016" role="button">2016</a></span><span> </span>
      </p>
      <p >&nbsp;</p>
    </div>


  );
}
export default YGCnav;

import {useState} from 'react';
import '../styles/Home.css';
import YGCnav from '.././YGC-Nav'

const YGC = () => {

  return(



<div className="container">
<YGCnav ></YGCnav>
<div>
<p className="center-align"><img src="/images/ygc/logo.png"alt="YGC 2023" width="150" height="150" className="rounded center center-align" ></img></p>
</div>

<div>
<h2 className="fbt-title"><img src="/images/homePage/Logo.jpg"alt="" width="40" height="40" className="rounded"/>  for a <span className="fbt-title-sub">better</span> tomorrow </h2>
</div>

<div>
<h3 className="center-align c-gray"><b >Youth Global Citizen (YGC)</b></h3>
</div>

<p >&nbsp;&nbsp;</p>
<div>
<h4 className="justify c737373">Youth Global Citizen (YGC) is an experiential program for local high school students that develops leadership skills and imparts the significance of service and philanthropy.  Participants develop self-awareness, leadership skills, and learn about diversity, poverty, values, and socioeconomic disparities in our community and in our world. By the end of the program, participants will have invested over fifty hours in program activities and information sessions.</h4>
</div>

<div>
<h4 className="justify c737373">However, YGC entails much more than leadership training; it also provides participants with an opportunity to use their newfound skills and awareness to help others and in so doing make a positive contribution to their community and their world.</h4>
</div>

<p >&nbsp;&nbsp;</p>
<div>
<h4 className="center-align c737373"><b >Learning Sessions</b></h4>
</div>

<div>
<h4 className="justify c737373">The YGC program covers a wide range of topics during the learning sessions, which are facilitated by community members with a depth of knowledge and expertise.</h4>
</div>

<div className="row">

<div className="col-sm">

<ul>
<li >1. Saturday, July 27th - Kickoff: 10:00am - 2:00pm</li>
<li >2. Saturday, August 3rd - Learning Session: 10:00 am - 8:00 pm</li>
<li >3. Saturday, August 10th - Learning Session: 10:00 am - 8:00 pm</li>
<li >4. Saturday, August 17th - Learning Session: 10:00 am - 8:00 pm</li>
<li >5. Saturday, September 28th - Closing Session: 10:00 am - 4:00 pm</li>
</ul>

</div>

</div >

<p >&nbsp;&nbsp;</p>
<div>
<h4 className="center-align c737373"><b >Learning Session Topics</b></h4>
</div>

<div>
<h4 className="justify c737373">Learning session topics addressed in YGC may include:</h4>
</div>

<div className="row">

<div className="col-sm">

<ul>
<li >Self-Awareness Development</li>
<li >Personal Strength Identification</li>
<li >Social Justice</li>
<li >Servant Leadership</li>
<li >Team Building and Collaboration</li>
</ul>

</div>

<div className="col-sm">

<ul>
<li >Fundraising Strategies</li>
<li >For a Better Tomorrow Operating Model</li>
<li >Presentation Skills</li>
<li >Values Clarification</li>
<li >Emotional Intelligence</li>
</ul>

</div>

</div >

<p >&nbsp;&nbsp;</p>
<div>
<h4 className="justify c737373">We ask all program participants to fully commit to the sessions. If you are unable to attend all the program sessions in person, please consider applying for the program next year. Besides the learning events, please be prepared to spend at least two additional hours a week in August and September to complete your project assignment.</h4>
</div>

<p >&nbsp;&nbsp;</p>
<div>
<h4 className="center-align c737373"><b >Project Overview</b></h4>
</div>

<div>
<h4 className="justify c737373">Participants work actively to support a local non-profit organization, and this active involvement allows participants to put their developing leadership skills to immediate use. By pairing learning with action, YGC ensures that participants are knowledgeable about how they can become engaged citizens and equips participants to do so through hands-on experience in the program.</h4>
</div>

<p >&nbsp;&nbsp;</p>
<div>
<h4 className="center-align cBlue"><b ><a href="https://docs.google.com/document/d/10si-3x2quUG_PT_Ppa_HlaUJcNVAvY86asyzwmrNayU/edit#heading=h.6jynaot9cbnq">Click here for additional information</a></b></h4>
</div>

<p >&nbsp;&nbsp;</p>
<div className="row">

<div className="col-sm-1 ">

</div>

<div className="col-sm-4 bg-info border">

<p ></p>
<h3 className="center-align c-white"   ><a href="http://tinyurl.com/ygc-app"><b><span className="c-white">Interested</span></b></a></h3>
<h4 className="center-align c-ivory"   ><a href="http://tinyurl.com/ygc-app"><b><span className="c-ivory">Let us know with this quick form</span></b></a></h4>
<p ></p>
</div>

<div className="col-sm-2 ">

</div>

<div className="col-sm-4  bg-info border">

<p ></p>
<h3 className="center-align c-white"   ><a href="http://tinyurl.com/ygc-nom"><b><span className="c-white">Nominations</span></b></a></h3>
<h4 className="center-align c-ivory"   ><a href="http://tinyurl.com/ygc-nom"><b><span className="c-ivory">Share your recommendations here</span></b></a></h4>
<p ></p>
</div>

<div className="col-sm-1 ">

</div>

</div>

<p >&nbsp;&nbsp;</p>
</div>




  );
}
export default YGC;

import { useState } from 'react';
import '../../styles/Home.css';
import Wip from '../.././wip';
import YGCnav from '../.././YGC-Nav';

const ygc2024 = () => {

  return (



    <div className="container">
      <YGCnav ></YGCnav>
      <div>
        <p className="center-align"><img src="/images/ygc/2024/YGC_2024.png" alt="" width="200" height="100" className="rounded center center-align" ></img></p>
      </div>

      <div>
        <h3 className="center-align"><b >Class of 2024</b></h3>
      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div className="row">

        <div className="col-sm">

          <table className="table table-borderless" >

            <tbody>

              <tr className="row">
                <td className="col-md-12 center-align"><img src="/images/ygc/2024/YGC_Class_2024.png" alt="" className="rounded" width="70%"></img></td>
              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/DhruvDalvi.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Dhruv Dalvi</b></span></h6>
                  <h6 >Dhruv is a very outgoing person who loves to make friends with everyone. He loves music, theatre, and science. Dhruv unique in his own way, which makes him a very likable person. He enjoys spending time with friends and family whenever possible, as they are his supports and trusted people.</h6>
                  <h6 ><i >Dhruv s favorite quote:  The two most important days in your life are the day you are born and the day you find out why.    Mark Twain</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/JeteeshiGiri.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Jeeteshi Giri</b></span></h6>
                  <h6 >Jeeteshi Giri is a persevering individual, who strives for success, whether it be in a classroom or in an athletic environment. She constantly tries to improve upon her mistakes, and looks for new oppurtunities.</h6>
                  <h6 ><i >Jeeteshi s favorite quote:  A month from now.. you can either have a month of progress, or a month of excuses why you didn't.  </i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/ManvithaLella.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Manvitha Lella</b></span></h6>
                  <h6 >Manvitha is a soon-to-be freshman who will be going to Tri-Valley High School. She was born in India and moved to the US when she was young. She first went to school at Northpoint Elementary until Covid-19 hit when she was in 4th grade. During lockdown, Manvitha moved houses and started her 5th grade year at Tri-Valley Middle School. She is now finished with middle school and ready for her high school years.</h6>
                  <h6 ><i >Manvitha s favorite quote: "Competition is not about tearing others down, but about building yourself up."</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/NikhilKavutarapu.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Nikhil Kavutarapu</b></span></h6>
                  <h6 >Nikhil is an upcoming Junior from Normal Community High School. He strives to continue learning new things, as he believes knowledge in invaluable. He participates in a variety of activities, such as Math Competitions or Chess Tournaments. He is a hard worker, and giving up is never an option for him. Instead, he perseveres. One of Nikhil's biggest goals is to make a large impact in his community!</h6>
                  <h6 ><i >Nikhil s favorite quote: "The greatest glory in living lies not in never falling, but in rising every time we fall."   Nelson Mandela</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/PranavBhookya.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Pranav Bhookya</b></span></h6>
                  <h6 >Pranav Bhookya is a current junior at Normal Community high school. He is a very outgoing person: he enjoys meeting new people, talking to old friends, and hanging out with his current ones. Pranav also enjoys being outside, he likes playing sports, going for a swim in the lake, hiking, and driving around any chance he gets. Pranav really likes to read as well, anything from books to newspaper articles, anything that he finds interesting. Nonetheless, Pranav is quite the hard-worker and he s overall a great person. </h6>
                  <h6 ><i >Pranav s favorite quote:  Don't stop when you're tired. Stop when you're done." - David Goggins</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/PranavKosaraju.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Pranav Kosaraju</b></span></h6>
                  <h6 >Pranav is an incoming freshman to NCHS who is passionate about technology and games. Currently excelling in subjects like Math and Science, Pranav is particularly fond of biology and exploring chemistry. Pranav is also an active member of the school's STEM Club and activities, where he enjoys doing science experiments and learning technology. Outside of academics, Pranav is interested in music and play viola and also participates in Robotics. Pranav loves to play outdoor as well as video games and enjoys playing basket ball and cricket.</h6>
                  <h6 ><i >Pranav s favorite quote:  Education is the strongest tool we have to make a difference in the world."</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/PranikaKurella.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Pranika Kurella</b></span></h6>
                  <h6 >Pranika Kurella is currently a sophomore at NCHS. She is a person with a wide range of interests. She loves the thrill but values tranquility. One thing she also loves is learning about medicine/science. Her favorite animals are snakes and white tigers. She catches snakes and other reptiles but strongly dislikes frogs. She adores the unpredictability of the wilderness and despises city life. Her ultimate goal is to become the best surgeon in the world, but above all, she loves fettucini alfredo.</h6>
                  <h6 ><i >Pranika s favorite quote:  Fake it  till you make it. </i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/RiannaEvans.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Rianna Evans </b></span></h6>
                  <h6 >Rianna lives with her parents and older brother in Bloomington Illinois. She goes to Bloomington High School. Shes loves spending time with her friends, going on bike rides, and trips to the beach. Rianna loves music, she sings and plays the piano. She s in her school choir and is a vocalist for the junior high worship band at Eastview Christian Church. </h6>
                  <h6 ><i >Rianna s favorite quote:  Just one small positive thought in the morning can change your whole day.  -Dalai Lama</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/SavannahCrews.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Savannah </b></span></h6>
                  <h6 >Savannah is an outgoing girl who kinda likes to do new things. Shes not very organized, but she still gets things done even if its not on time. She spends alot of time with her best friends, but when shes not she spends her time with her family and enjoys every minute. Savannah knows a thing or two on team work because she is in marching. She works with them all the time weather its at rehearsal or competition. She also loves to win but when she loses she doesnt get to upset, she learns from the experiences and help her to do better next time. Savannah is not a big fan on meeting new people, but she hopes this organization can help her communicate more and make more friends. I am the type of person to get work done when asked to. I think about my future alot and how I can succeed in life and make sure I can take care of myself and my family. I am not that organized but I still get things done even if its a little late. I am in marching band so I know a thing or two on teamwork. Weather if its just for rehearsal or for competition. And I lobe to win at things but wouldn't be mad if I lost because I can learn from that and win the next time.</h6>
                  <h6 ><i ></i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/SoumiliAich.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Soumili Aich</b></span></h6>
                  <h6 >Soumili Aich is a highschooler who aspires to learn and explore as much as she can about the world. She aims to make a positive difference in her community, even if it is a small one. Her strategic approach to every problem and analyzing different outcomes leads her to flawlessly choose the best route both for her, and the people she works with. Additionally, Soumili is an outgoing person who enjoys getting to know new people.</h6>
                  <h6 ><i >Soumili s favorite quote: "The self is made, not given"</i></h6>
                </td>

              </tr>

              <tr className="row">
                <td className="col-md-3 center-align"><img src="/images/ygc/2024/ViswambariDevari.jpg" alt="" width="150" height="150" className="rounded-circle"></img></td>
                <td className="col-md-9">
                  <h6 ><span ><b >Viswambari Devari</b></span></h6>
                  <h6 >Vishu is an incoming sophomore at Normal Community High School, known for her diverse interests and activities. She loves biking, dancing, skating, and gymnastics, and her passion for learning extends to engineering, science, math, and computer science. At school, Vishu is actively involved in clubs such as FBLA (Future Business Leaders of America), Key Club, and the Film Production Club. She is also dedicated to volunteering and frequently helps out in her community. For the past two years, Vishu has been a valuable member of the FBT Design Team, creating engaging flyers and social media posts.</h6>
                  <h6 ><i >Vishu s favorite quote: "The only way to do great work is to love what you do." - Steve Jobs</i></h6>
                </td>

              </tr>

            </tbody>

          </table>

        </div>

      </div>

    </div>


  );
}
export default ygc2024;

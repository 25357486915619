import { useState } from 'react';
import '../styles/Home.css';
import '../styles/grant.css';

const grant = () => {

  return (

    <div className="container">

      <div>
        <h2 className="fbt-title"><img src="/images/base/Logo.jpg" alt="" width="40" height="40" className="rounded" />  for a <span className="fbt-title-sub">better</span> tomorrow </h2>
        <h2 className="fbt-tag-line">…Eliminating the gap between abundance and need</h2>
        <p >&nbsp;</p>
      </div>

      <div>
        <h1 className="center-align"><b >Submit a Grant</b></h1>
      </div>

      <div><hr class="hr-line"></hr></div>

      <p >&nbsp;&nbsp;</p>
      <div>
        <h4 className="center-align">Thanks for your interest in working with For A Better Tomorrow (FBT). FBT accepts grant applications in the first and the third quarter of the year. Before submitting a grant request, kindly take a few minutes to review the <a href="https://docs.google.com/document/d/1Rh1vmcKUQsfyLjP9HIa1jrgSogazw-1WlVUV4Hwu-0o/edit">FBT Grant Guidelines</a>. We love collaborating with new nonprofits and past grantees alike. We look forward to receiving and reviewing your grant applications. <span className="afs">As of July 2024, FBT will not accept applications from organizations who were awarded grants in the previous grant cycle.</span></h4>
        <p >&nbsp;&nbsp;</p>
      </div>

      <div>
        <h4 className="center-align">FBT will review and decide on grants submitted in the:</h4>
        <h4 className="center-align">First Quarter (Jan 1<sup>st</sup> to March 31<sup>st</sup>) by the end of Second Quarter (June 30<sup>th</sup>)</h4>
        <h4 className="center-align">Third Quarter (July 1<sup>st</sup> to September 30<sup>th</sup>) by the end of Fourth Quarter (December 31<sup>st</sup>)</h4>
      </div>

      <p >&nbsp;&nbsp;</p>
      <div className="row container2">

        <div className="col-sm-1 ">

        </div>

        <div className="col-sm-4">

          <img src="/images/base/BlueSquare2.png" alt="" width="100%" className="rounded-circle"></img>
          <div className="centered">

            <h4 className="cF8F8F8 center-align"><b ><a href="https://docs.google.com/forms/d/e/1FAIpQLSdhNobh1VopMuIDSFMtb30HetDznRQKkS6Q558jTAka2A2sWA/viewform ">Submit a Global Grant Request.</a></b></h4>
            <p >&nbsp;</p>
          </div>

        </div>

        <div className="col-sm-2 ">

        </div>

        <div className="col-sm-4">

          <img src="/images/base/BlueSquare2.png" alt="" width="100%" className="rounded-circle"></img>
          <div className="centered">

            <h4 className="cF8F8F8 center-align"><b ><a href="https://docs.google.com/forms/d/e/1FAIpQLSdSgC_GSdBKMDTNGLvJ5EZ62Ke1Lgf8mZIlRiUQeOztQK1vGQ/viewform ">Submit a Local (McLean County) Grant Request</a></b></h4>
            <p >&nbsp;</p>
          </div>

        </div>

        <div className="col-sm-1 ">

        </div>

      </div>

      <div className="row container2">

        <div className="col-sm">

          <img src="/images/base/BlueSquare2.png" alt="" width="100%" className="rounded-circle"></img>
          <div className="centered">

            <h5 className="cF8F8F8 center-align"><b ><a href=" https://drive.google.com/file/d/1gGrOwL-EWblfNMbThpmOM9cjIqQ0142n/view?usp=drivesdk ">Frequently Asked Questions (FAQs) <br />
              For A Better Tomorrow (FBT) Global Grant Process.</a></b></h5>
            <p >&nbsp;</p>
          </div>

        </div>

      </div>

      <div><hr class="hr-line"></hr></div>

    </div>



  );
}
export default grant;

import {useState} from 'react';
import '../styles/Home.css';

const NotFoundPage = () => {

  return(


      <div className="container">
        <h3>Page Not Available check URL entered.</h3>


      </div>



  );
}
export default NotFoundPage;
